
import { Component, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import Icon from "@/components/reusable/Icon.vue";
import EditTableMenu from "@/components/reusable/editable_table/EditTableMenu.vue";
import EditTable from "@/components/reusable/editable_table/EditTable.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import {
  CategoryAttributeModel,
  CategoryAttributeRequestOptions
} from "@/models/category_attribute";
import CategoryAttributeService from "@/services/category_attribute_service";
import { APIResponse } from "@/models/api_res";
import { AuthError, AttributeConflictError } from "@/services/error_service";
import { EventBus } from "@/events/index";

@Component({
  components: {
    draggable,
    Icon,
    EditTableMenu,
    FormInput,
    Autocomplete
  }
})
export default class AttrEditTable extends EditTable {
  @Prop({
    default: () => [] as CategoryAttributeModel[]
  })
  data!: CategoryAttributeModel[];
  @Prop({ default: false }) disableDrag!: boolean;
  @Prop({ default: 0 })
  categoryId!: number | string;
  attrList: CategoryAttributeModel[] = [];
  protected categoryAttributeService = new CategoryAttributeService();

  @Watch("tableData", { deep: true, immediate: false })
  protected onUpdate(): void {
    this.$emit("update", this.tableData);
    if (this.tableData.length < 1) {
      this.addRow();
    }
  }
  mounted() {
    if (this.data.length < 1) {
      this.tableData = [];
      this.addRow();
    } else {
      this.tableData = [...this.data];
      this.updateEditTags();
    }
  }

  beforeDestroy() {
    EventBus.$off("checkAttributes");
  }

  protected addRow(): void {
    const id = this.randomStringId();
    const newObj: any = { id: id, isEdit: true };
    this.isEditTags.push(newObj);
    const emptyRow: CategoryAttributeModel = {
      handle: "",
      name: "",
      ak_id: undefined,
      sort_order: undefined,
      id: id
    };
    this.tableData.push(emptyRow);

    // scroll to element and auto focus
    const root = document.querySelector("html");
    root?.scrollTo({
      left: root.scrollLeft,
      top: root.scrollHeight + 500,
      behavior: "smooth"
    });
    setTimeout(() => {
      const inputFields = document.querySelectorAll(".attribute-name-input");
      const inputField = (inputFields?.length > 0
        ? inputFields[inputFields.length - 1]
        : null) as HTMLInputElement | null;
      if (inputField) {
        inputField.click();
      }
      setTimeout(() => {
        const searchInputs = document.querySelectorAll(
          ".autocomplete-search-input"
        );
        const searchInput = (searchInputs?.length > 0
          ? searchInputs[searchInputs.length - 1]
          : null) as HTMLInputElement | null;
        if (searchInput) {
          searchInput.focus();
        }
      }, 200);
    }, 200);
  }

  deleteRow(row: CategoryAttributeModel) {
    const index = this.tableData.findIndex(item => item.id === row.id);
    if (index > -1) {
      this.tableData.splice(index, 1);
    }
    if (this.tableData.length < 1) {
      this.addRow();
    }
  }

  /**
   * isEditTags holds an id  & associated boolean which toggles edit on
   * row with matching id.
   *
   * This method iterates through array of objects and builds an object to represent each table row in isEditTags
   * @example ...
   * [{id: 12, isEdit: false}, { id: 22, isEdit: false}]
   */
  protected updateEditTags(): void {
    this.isEditTags = this.tableData.map(row => {
      return { id: row.id, isEdit: false };
    });
  }

  /** Returns whether input is editable */
  public editInput(rowId: number): boolean | null {
    const row: any[] = this.isEditTags.filter(row => {
      if (row.id === rowId) {
        return row; // return matching item in isEditTags (row will = { id: number, isEdit: boolean} )
      }
    });
    if (row[0]) {
      // if row exists, return isEdit value (boolean)
      return row[0].isEdit;
    } else {
      return null; // no match, no change
    }
  }

  async updateAttributeInput(item: CategoryAttributeModel, rowId: number) {
    this.tableData = this.tableData.map(row => {
      if (row.id === rowId) {
        row = {
          ...row,
          ...item
        };
      }
      return row;
    });
  }

  protected async getAttributes(
    optionsObject: CategoryAttributeRequestOptions
  ): Promise<void> {
    try {
      const res = await this.categoryAttributeService.getAttributes(
        optionsObject
      );
      const addedIds = this.tableData.map(item => item.id);
      this.attrList = (res || []).filter(item => !addedIds.includes(item.id));
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  autoCompleteFetchAttr(value: any): void {
    this.getAttributes({ id: this.categoryId, q: value });
  }

  handleKeyDown(event: KeyboardEvent) {
    event.preventDefault();
  }
}
