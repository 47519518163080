
import { Component, Vue } from "vue-property-decorator";
import Wysiwyg from "@/components/reusable/Wysiwyg.vue";
import FormInput from "@/components/reusable/FormInput.vue";
import SearchDropDown from "@/components/reusable/SearchDropDown.vue";
import Icon from "@/components/reusable/Icon.vue";
import FormTextArea from "@/components/reusable/FormTextArea.vue";
import { CategoryModel, CategoryModelRequest } from "@/models/category";
import CategoryService from "@/services/category_service";
import CategoryAttributeService from "@/services/category_attribute_service";
import CategoryMenu from "@/components/category/CategoryMenu.vue";
import UIkit from "uikit";
import { EventBus } from "@/events/index";
import { namespace } from "vuex-class";
import { StoreModule } from "@/store/types";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { urlName } from "@/validators/custom";
import { APIResponse } from "@/models/api_res";
import { AuthError, NotFoundError } from "@/services/error_service";
import ImagePicker from "@/components/reusable/ImagePicker.vue";
import { GlobalActions, GlobalGetters } from "@/store/modules/global/types";
import ConfirmDelete from "../reusable/modals/ConfirmDelete.vue";
import AttrEditTable from "@/components/category/AttrEditTable.vue";
import { AssetModel } from "@/models/asset";
import { AttributeModel } from "@/models/attribute";
import { CategoryAttributeModel } from "@/models/category_attribute";
Component.registerHooks(["beforeRouteLeave"]);

@Component({
  components: {
    Wysiwyg,
    FormInput,
    FormTextArea,
    SearchDropDown,
    Icon,
    CategoryMenu,
    ImagePicker,
    AttrEditTable
  }
})
export default class CategoryEditor extends Vue {
  protected justPosted = false;
  protected showWarningModal = false; // if save/post method is called, this will change to false. Passed to child component <Save> through showWarning() method to trigger warning modal
  protected categoryService = new CategoryService();
  protected categoryAttributeService = new CategoryAttributeService();
  protected isNew = false;
  
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetBusinessUnit))
  businessUnit!: string;

  protected category: CategoryModel | CategoryModelRequest = {
    is_hidden: false
  } as CategoryModel;
  public attributes: CategoryAttributeModel[] = [];
  protected parentObject = { id: 0 } as CategoryModel;
  id = 0;
  protected toast = false;
  protected messageHtml = "";
  protected className = "success";
  protected categoryList: CategoryModel[] = [];
  protected deleteData: CategoryModel[] = [];
  protected attributeDeleteData: AttributeModel[] = [];
  isAttributesListVisible = false;
  @(namespace(StoreModule.Global).Getter(GlobalGetters.GetLoading))
  isLoading!: boolean;
  @(namespace(StoreModule.Global).Action(GlobalActions.AddLoading))
  setLoading: any;

  created() {
    this.setLoading(true);
    if (!this.$route.params.id) {
      this.isNew = true;
      if (this.$route.query.parent) {
        this.category.parent_id = parseInt(
          decodeURIComponent(this.$route.query.parent as string),
          10
        );
        this.getParentObject();
      }
      this.setLoading(false);
    } else {
      this.id = parseInt(this.$route.params.id, 10);
      this.getSingleCategory();
    }
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    if (this.showWarningModal) {
      UIkit.modal
        .confirm(
          `
    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <span
          uk-icon="icon: warning; ratio:1.5;"
          class="red no-hover uk-margin-small-right"
        ></span>
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>`
        )
        .then(
          function () {
            next();
          },
          function () {
            next(false);
          }
        );
    } else {
      next();
    }
  }

  protected showWarning(isVisible: boolean): void {
    this.showWarningModal = isVisible;
  }

  mounted() {
    EventBus.$on(
      "deleteConfirmed",
      (id: number, name: string, final = false) => {
        this.deleteRequest(id, name, final);
      }
    );
    EventBus.$on(
      "sendImage",
      (files: AssetModel[]) => (this.category.image = files[0])
    );
    /** Global event listener for data deletion. Triggers & sends array of data selected for deletion through to confirmation modal.
     * This event is called from the <Delete> component (a child in the corresponding <Menu> component [@ex: <ProductMenu>, <MfrMenu>...]) and from the base <Table> component.
     */
    EventBus.$on("deleteRow", (data: CategoryModel[]) => {
      this.deleteData = data;
      this.$modal.show(
        ConfirmDelete,
        { deleteData: this.deleteData, type: "category" },
        { height: "auto", adaptive: true }
      );
      //   UIkit.modal(
      //     document.getElementById("delete-modal") as HTMLElement
      //   ).show();
      // });
    });
  }

  protected makeProductLine(): void {
    this.showWarningModal = true;
    this.category.parent_id = 0;
    this.parentObject = { id: 0 } as CategoryModel;
  }

  beforeDestroy() {
    EventBus.$off("deleteConfirmed");
    EventBus.$off("deleteRow");
    EventBus.$off("sendImage");
    /** UIkit modals do not leave the DOM unless explicitly destroyed. Destroying them helps with buggy functionality due to dynamic data. This method loops through all of the modal ids and remove
     * them from the DOM upon vue's beforeDestroy() lifecycle hook.
     *
     * Note that typescript does not have definitions for many UIkit methods, hence //@ts-ignore flag.
     */
    const modals = [
      "#delete-modal",
      "#move-modal",
      "#confirm-moving-modal",
      "#add-model",
      "#save-modal"
    ];
    modals.forEach(selector => {
      const component = UIkit.modal(selector);
      if (component) {
        //@ts-ignore
        component.$destroy(true);
      }
    });
  }
  @Validations()
  validations() {
    return {
      category: {
        display_name: { required },
        url_name: { urlName }
      }
    };
  }

  /** Triggered by autocomplete child/grandchild component.
   *
   * Value typed in search box in the <Autocomplete> component is sent up from children to this method, which sends query to API in HTTP request
   */
  protected autoCompleteFetchCat(value: any): void {
    this.getCategories({ q: value, bu: this.businessUnit });
  }

  protected async getCategories(optionsObject?: {}): Promise<void> {
    try {
      const res: APIResponse = await this.categoryService.getCategories(
        optionsObject
      );
      this.categoryList = res.results;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * Method that reads updates from the Quill text editors <Wsywig>
   * Each Wsywig is identified by "editor{number}" (computed properties) and mapped to a specific data field
   */
  protected receiveUpdates(dataName: string, data: any): void {
    if (dataName === this.editor1) {
      this.category.overview = data;
    } else if (dataName === this.editor2) {
      this.category.summary = data;
    }
  }

  protected async getSingleCategory(): Promise<void> {
    try {
      this.isAttributesListVisible = false;
      const res: CategoryModel = await this.categoryService.getSingleCategory(
        this.id
      );
      this.category = res;
      const attributes =
        (await this.categoryAttributeService.getAttributes({
          id: this.id
        })) || [];
      this.attributes = attributes.filter(({ ak_id }) => ak_id);
      if (this.category.parent_id) {
        this.getParentObject();
      }
      this.isAttributesListVisible = true;
      this.setLoading(false);
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof NotFoundError) {
        this.$router.replace({
          name: "NotFound",
          query: { error: encodeURI(err.message) }
        });
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }
  protected saveKeypress(): void {
    this.save();
  }
  protected get website(): string {
    return process.env.VUE_APP_C5_URL;
  }

  /**
   * Value emitted from child component
   */
  protected updateParent(parent: string) {
    this.category.parent_id = parent;
    this.getParentObject();
  }

  /**
   * Fetch info for parent category.
   *
   * API /get request only returns parent_id, so we have to lookup the rest of the data.
   */
  protected async getParentObject(): Promise<void> {
    const res: CategoryModel = await this.categoryService.getSingleCategory(
      this.category.parent_id as number
    );
    this.parentObject = res;
  }

  protected cancel(): void {
    this.$router.go(-1);
  }

  protected closeToast(): void {
    this.toast = false;
  }

  protected showToast(message: string, className: string): void {
    this.messageHtml = message;
    this.className = className;
    this.toast = true;
  }

  updateAttributesList(attributes: CategoryAttributeModel[]) {
    this.attributes = attributes;
  }

  protected save(): void {
    this.$v.category.$touch();
    if (!this.$v.$invalid) {
      this.showWarningModal = false;
      if (this.isNew) {
        this.postNew();
      } else {
        this.saveExisting();
      }
    } else {
      this.$nextTick(() => {
        (this.$refs.requiredFields as HTMLDivElement).scrollIntoView();
      });
    }
  }

  protected buildRequest(): CategoryModelRequest {
    const req = { ...this.category };
    req.business_unit = this.businessUnit;
    if (this.category.image && typeof this.category.image !== "number") {
      req.image = (this.category.image as AssetModel).id;
    }
    if (req.id) {
      const attributes = this.attributes
        .map(item => item.id)
        .filter(id => !id?.toString().includes("new"));
      (req as any).attributes = attributes;
    }
    return req as CategoryModelRequest;
  }

  protected async postNew(): Promise<void> {
    const req = this.buildRequest();
    try {
      const res = await this.categoryService.createNewCategory(
        req as CategoryModelRequest
      );
      this.category = res;
      this.$router.push({
        path: "/category/edit/" + res.id,
        query: {
          created: encodeURI(`${this.category.display_name}`),
          type: "category"
        }
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  protected async saveExisting(): Promise<void> {
    const req = this.buildRequest();
    try {
      await this.categoryService.saveCategory(
        req as CategoryModelRequest,
        this.category.id as number
      );
      if (this.$route.query.created) {
        this.$router.push({ query: {} });
      }
      this.showWarningModal = false;
      EventBus.$emit(
        "showSuccess",
        `Category <strong>${this.category.display_name}</strong> has been ${this.action}. <a href="${this.website}/products${this.category.url}" target="_blank">View Live</a>`,
        []
      );
      this.getSingleCategory();
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  /**
   * @param id id of item to be deleted
   * @param name name of item to be deleted, used in <Toast> confirmation
   * @param final optional, default: false, flags the final item in the request array; triggers <Toast> confirmation, refreshes data
   *
   * in the <{Path}Editor> component, @param final is not used.
   */
  protected async deleteRequest(
    id: number,
    name: string,
    final = false
  ): Promise<void> {
    this.showWarningModal = false;
    try {
      await this.categoryService.deleteCategory(id);
      this.$router.push({
        path: "/category",
        query: {
          deleted: encodeURI(`${this.category.display_name}`),
          type: "category"
        }
      });
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
  }

  get action(): string {
    let action = "saved";
    if (this.isNew) {
      action = "created";
    }
    return action;
  }

  get editor1(): string {
    return "overview";
  }

  get editor2(): string {
    return "page-list";
  }
}
