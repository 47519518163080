
import { Component, Prop, Vue } from "vue-property-decorator";
import Autocomplete from "@/components/reusable/Autocomplete.vue";
import { CategoryModel } from "@/models/category";
@Component({
  components: { Autocomplete }
})
export default class SearchDropDown extends Vue {
  /** Parent object prop is whatever should be in the dropdown field as default. Cannot be null, so this component is hidden by a v-if if there is no default value returned from API where this component is used
   */
  @Prop() parentObject!: CategoryModel;
  @Prop() data!: CategoryModel[];
  protected value = {} as CategoryModel;
  protected name = "";

  created() {
    this.value = this.parentObject;
  }

  protected keyChange(value: any): void {
    this.$emit("keyChange", value);
  }

  protected updateField(
    item: { id: number; display_name: string },
    id: number
  ) {
    this.$emit("updateParent", item.id);
  }
}
